<template>
  <icon-button
    data-cy="attach-file-button"
    :icon="IconAttach"
    @click="isModalOpen = true"
  />
  <lf-modal
    v-if="isModalOpen"
    :title="$t('DEALS.DOCUMENTS.ATTACH_DOCUMENTS')"
    :close="closeModal"
    small
  >
    <template v-slot:content>
      <div class="flex flex-col items-stretch h-[calc(100%-52px)]">
        <upload-box
          class="call-log"
          data-cy="upload-call-recording"
          :accept="ACCEPT"
          @files:add="handleAttachFile"
        />
      </div>
    </template>
  </lf-modal>
</template>

<script setup lang="ts">
import { ref } from "vue";
import IconAttach from "@/components/icons/IconAttach.vue";
import IconButton from "@/components/ui/buttons/IconButton.vue";
import UploadBox from "@/components/ui/inputs/UploadBox.vue";
import LfModal from "@/components/ui/Modal.vue";

const ACCEPT = ".doc, .docx, .gif, .heic, .jpg, .pdf, .png, .xls, .xlsx";

const emit = defineEmits(["attach-file"]);

const isModalOpen = ref(false);

const closeModal = () => {
  isModalOpen.value = false;
};

const handleAttachFile = (files: FileList) => {
  emit("attach-file", files);
  closeModal();
};
</script>
